import styled from 'styled-components'

export const StyledContainer = styled.div`
  flex-direction: column;
  max-width: 220px;
  width: auto;
  flex: 1 1;
  border: 1px solid;
  border-color: ${(props) => props.cardBorderColor};
  box-shadow: ${(props) => props.cardShadow};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 0;
  border-radius: 20px;
  cursor: pointer;
  color: var(--brand-font-default-color);
  background-color: var(--brand-primary-white-color);
  overflow: hidden;

  &:hover {
    color: var(--brand-primary-white-color);
    background-color: var(--brand-primary-black-color);

    .chevron-icon path {
      fill: white;
    }
  }

  .content-container {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 12px;
    font-family: var(--brand-font-family-text);
    font-style: normal;

    .categoryName {
      display: inline;
      margin-right: 20px;
    }
  }

  .image-container {
    width: 100%;
    height: 201px;
    display: flex;
    background-color: ${(props) => props.imageBgColor};
    .card-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`
